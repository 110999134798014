export default [
  {
    header: 'User Interface',
    icon: 'LayersIcon',
    children: [
      {
        title: 'Typography',
        route: 'ui-typography',
        icon: 'TypeIcon',
      },
      {
        title: 'Colors',
        route: 'ui-colors',
        icon: 'DropletIcon',
      },
      {
        title: 'Feather',
        route: 'ui-feather',
        icon: 'EyeIcon',
      },
    ],
  },
]
