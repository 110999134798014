export default [
  {
    header: 'Pages',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Miscellaneous',
        icon: 'FileIcon',
        children: [
          {
            title: 'Not Authorized',
            route: 'misc-not-authorized',
            target: '_blank',
          },
        ],
      },
    ],
  },
]
