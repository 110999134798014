<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
    @mouseenter="
      $route.name != 'amg-messages' ? updateMouseHovered(true) : null
    "
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded" style="height: 5.45rem">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              :to="currentUser.user_id == 1 ? '/home' : '/dashboard'"
            >
              <span>
                <!-- <AmgPrideIcon /> -->
                <amg-icon
                  icon="AmgIcon"
                  size="44"
                  :class="
                    skin == 'dark' || skin == 'semi-dark'
                      ? 'text-light'
                      : 'text-dark'
                  "
                />
              </span>
              <h2
                class="brand-text"
                :class="
                  skin == 'dark' || skin == 'semi-dark'
                    ? 'text-light'
                    : 'text-dark'
                "
              >
                {{ appName }}
              </h2>
            </b-link>
          </li>
          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link
              class="nav-link modern-nav-toggle"
              style="margin-top: 28px"
              :class="
                skin == 'dark' || skin == 'semi-dark'
                  ? 'text-light'
                  : 'text-dark'
              "
            >
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      "
    >
      <slot
        name="items"
        :isMouseHovered="isMouseHovered"
        :isVerticalMenuCollapsed="isVerticalMenuCollapsed"
      >
        <!-- <vertical-nav-menu-items
          :items="navMenuItems"
          class="navigation navigation-main"
          style="font-size: 14px;"
        /> -->
      </slot>
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BLink, BImg } from "bootstrap-vue";
import { provide, computed, ref } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import navMenuItems from "@/navigation/vertical";
import { mapGetters, mapActions, mapMutations } from "vuex";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";
export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide("isMouseHovered", isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === "unpinned" ? "CircleIcon" : "DiscIcon"
    );

    // App Name
    const { appName, appLogoImage } = $themeConfig.app;

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    };
  },
  data() {
    return {
      activePagesArray: [],
      subModules: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/G_USER_STATUS_SESSION_SUB_MODULE",
    }),
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
  },
  created() {
    this.subModules = [20, 22, 23, 24, 26, 6];
    if (this.subModules.includes(this.moduleId) && this.moduleId !== 24) {
      window.addEventListener("online", async () => {
        if (this.G_USER_STATUS_SESSION_SUB_MODULE !== 6)
          await this.changeStatusSession(1);
      });
      window.addEventListener("offline", async () => {
        if (this.G_USER_STATUS_SESSION_SUB_MODULE !== 6)
          await this.changeStatusSession(4);
      });
    }
  },
  methods: {
    ...mapActions({
      A_CHANGE_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/A_CHANGE_USER_STATUS_SESSION_SUB_MODULE",
    }),
    ...mapMutations({
      SET_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/SET_USER_STATUS_SESSION_SUB_MODULE",
    }),
    async changeStatusSession(status) {
      this.subModules = [22, 23, 26, 6];
      // >= 22 and <= 24 mean that it's a submodule of credit experts digital and != 24 mean that it's not settings
      if (this.subModules.includes(this.moduleId) && this.moduleId !== 24) {
        if (status === this.G_USER_STATUS_SESSION_SUB_MODULE) return;
        if (status === 2) {
          this.$emit("setBusy");
          return;
        }
        this.SET_USER_STATUS_SESSION_SUB_MODULE([{ status_session: status }]);
        const params = {
          created_by: this.currentUser.user_id,
          sub_module_id: this.moduleId,
          status_sub_module_id: status,
        };
        await this.A_CHANGE_USER_STATUS_SESSION_SUB_MODULE(params);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
