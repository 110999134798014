var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu menu-fixed menu-accordion menu-shadow",class:[
    {
      expanded:
        !_vm.isVerticalMenuCollapsed ||
        (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered),
    },
    _vm.skin === 'semi-dark' ? 'menu-dark' : 'menu-light' ],on:{"mouseenter":function($event){_vm.$route.name != 'amg-messages' ? _vm.updateMouseHovered(true) : null},"mouseleave":function($event){return _vm.updateMouseHovered(false)}}},[_c('div',{staticClass:"navbar-header expanded",staticStyle:{"height":"5.45rem"}},[_vm._t("header",function(){return [_c('ul',{staticClass:"nav navbar-nav flex-row"},[_c('li',{staticClass:"nav-item mr-auto"},[_c('b-link',{staticClass:"navbar-brand",attrs:{"to":_vm.currentUser.user_id == 1 ? '/home' : '/dashboard'}},[_c('span',[_c('amg-icon',{class:_vm.skin == 'dark' || _vm.skin == 'semi-dark'
                    ? 'text-light'
                    : 'text-dark',attrs:{"icon":"AmgIcon","size":"44"}})],1),_c('h2',{staticClass:"brand-text",class:_vm.skin == 'dark' || _vm.skin == 'semi-dark'
                  ? 'text-light'
                  : 'text-dark'},[_vm._v(" "+_vm._s(_vm.appName)+" ")])])],1),_c('li',{staticClass:"nav-item nav-toggle"},[_c('b-link',{staticClass:"nav-link modern-nav-toggle",class:_vm.skin == 'dark' || _vm.skin == 'semi-dark'
                ? 'text-light'
                : 'text-dark',staticStyle:{"margin-top":"28px"}},[_c('feather-icon',{staticClass:"d-block d-xl-none",attrs:{"icon":"XIcon","size":"20"},on:{"click":_vm.toggleVerticalMenuActive}}),_c('feather-icon',{staticClass:"d-none d-xl-block collapse-toggle-icon",attrs:{"icon":_vm.collapseTogglerIconFeather,"size":"20"},on:{"click":_vm.toggleCollapsed}})],1)],1)])]},{"toggleVerticalMenuActive":_vm.toggleVerticalMenuActive,"toggleCollapsed":_vm.toggleCollapsed,"collapseTogglerIcon":_vm.collapseTogglerIcon})],2),_c('div',{staticClass:"shadow-bottom",class:{ 'd-block': _vm.shallShadowBottom }}),_c('vue-perfect-scrollbar',{staticClass:"main-menu-content scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"},on:{"ps-scroll-y":function (evt) {
        _vm.shallShadowBottom = evt.srcElement.scrollTop > 0;
      }}},[_vm._t("items",null,{"isMouseHovered":_vm.isMouseHovered,"isVerticalMenuCollapsed":_vm.isVerticalMenuCollapsed})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }